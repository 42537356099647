<!--
 * @Description: 辅料库
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2022-11-25 15:54:09
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button type="primary" @click="changeStatus('shelf')"
            >上架</el-button
          >
          <el-button type="primary" @click="changeStatus('shelfOff')"
            >下架</el-button
          >
          <el-button type="primary" @click="changeStatus('delete')"
            >删除</el-button
          >
          <el-button type="primary" @click="importXlxs">导入</el-button>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="accessoryName">
              <el-input
                v-model="searchForm.accessoryName"
                placeholder="辅料名称"
                title="辅料名称"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="accessoryId">
              <el-input
                v-model="searchForm.accessoryId"
                placeholder="辅料ID"
                title="辅料ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="accessoryClass">
              <e6-vr-select
                v-model="searchForm.accessoryClass"
                :data="accessoryClassList"
                placeholder="辅料类型"
                title="辅料类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="enableStatus">
              <e6-vr-select
                v-model="searchForm.enableStatus"
                :data="accessoryEnableList"
                placeholder="状态"
                title="状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>
              <span v-else-if="column.fieldName === 'workNo'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleId'">
                <el-button type="text" @click="toSchedule(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleInfoId'">
                <el-button type="text" @click="toScheduleInfoDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <add-auxiliary-material
      :addVisible.sync="addVisible"
      :dialogType="dialogType"
      :accessoryClassList="accessoryClassList"
      :detailInfo="detailInfo"
      @refreshData="queryList"
    ></add-auxiliary-material>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getAccessoryList, batchUpdateStats, findDownList } from "@/api";
import addAuxiliaryMaterial from "./addAuxiliaryMaterial";
import { e3Api } from "@/config/api";
const { E3_BL_EWOS = "" } = e3Api;
export default {
  name: "auxiliaryMaterialList",
  data() {
    return {
      loading: false,
      queryListAPI: getAccessoryList,
      searchForm: {
        accessoryName: "",
        accessoryClass: "",
        accessoryId: "",
        enableStatus: "",
        pageIndex: 1,
        pageSize: 20
      },
      accessoryClassList: [], //辅料类型
      accessoryEnableList: [], //辅料状态
      total: 0,
      columnData: [
        {
          fieldName: "accessoryId",
          display: true,
          fieldLabel: "辅料ID",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accessoryName",
          display: true,
          fieldLabel: "辅料名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accessoryType",
          display: true,
          fieldLabel: "辅料型号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accessoryClassStr",
          display: true,
          fieldLabel: "辅料类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "enableName",
          display: true,
          fieldLabel: "状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updateUserName",
          display: true,
          fieldLabel: "操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTimeStr",
          display: true,
          fieldLabel: "最后修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      addVisible: false,
      dialogType: 1,
      detailInfo: {}
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: {
    addAuxiliaryMaterial
  },
  watch: {},
  computed: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [findDownList(["accessoryClass", "accessoryEnable"])];
        let [Res] = await Promise.all(promiseList);
        this.accessoryClassList = this.getFreezeResponse(Res, {
          path: "data.accessoryClass"
        });
        this.accessoryEnableList = this.getFreezeResponse(Res, {
          path: "data.accessoryEnable"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "详情",
          id: 2
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.dialogType = 2;
        this.addVisible = true;
      }
      if (val.id == 2) {
        this.dialogType = 3;
        this.addVisible = true;
      }
      this.detailInfo = row;
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "accessory_import",
          origin: "productManagement/auxiliaryMaterialList",
          maxDataSize: 500,
          serverName: E3_BL_EWOS
        }
      };
      this.routerPush(route);
    },
    //点击添加
    handleAdd() {
      this.addVisible = true;
      this.dialogType = 1;
    },
    //处理上架下架
    changeStatus(status) {
      if (!this.selColumns.length) {
        this.$message.warning("未勾选数据");
        return;
      }
      let tipTitle =
        status == "shelf"
          ? "是否确认上架勾选的数据？"
          : status == "shelfOff"
          ? "是否确认下架勾选的数据？"
          : "是否确认删除勾选的数据？";
      let title =
        status == "shelf" ? "上架" : status == "shelfOff" ? "下架" : "删除";
      this.$confirm(tipTitle, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.batchEnable(status);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量启用请求
    async batchEnable(status) {
      try {
        let res = await batchUpdateStats({
          keyIds: this.selColumns.map(item => item.keyId),
          updateType: status
        });
        if (res.code == "OK") {
          if (status == "shelf") {
            this.$message.success("上架成功");
          } else if (status == "shelfOff") {
            this.$message.success("下架成功");
          } else {
            this.$message.success("删除成功");
          }
          this.selColumns = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>

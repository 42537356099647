<!--
 * @Description: 添加/编辑辅料
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-22 16:31:21
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    :title="dialogType == 1 ? '添加' : dialogType == 2 ? '编辑' : '详情'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="50%"
    v-loading="editDialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
  >
    <div class="dialogTitle">
      <div class="line"></div>
      <div class="til">基础信息</div>
    </div>
    <el-form
      label-width="110px"
      :model="addForm"
      ref="addForm"
      :rules="addFormRules"
    >
      <el-form-item label="辅料ID：" prop="accessoryId">
        <el-input
          placeholder="辅料ID"
          title="辅料ID"
          v-model="addForm.accessoryId"
          :disabled="dialogType == 2 || dialogType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="辅料名称：" prop="accessoryName">
        <el-input
          placeholder="辅料名称"
          title="辅料名称"
          v-model="addForm.accessoryName"
          :disabled="dialogType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="辅料型号：" prop="accessoryType">
        <el-input
          placeholder="辅料型号"
          title="辅料型号"
          :disabled="dialogType == 3"
          v-model="addForm.accessoryType"
        ></el-input>
      </el-form-item>
      <el-form-item label="辅料类型:" prop="accessoryClass">
        <e6-vr-select
          v-model="addForm.accessoryClass"
          :data="accessoryClassList"
          placeholder="辅料类型"
          :disabled="dialogType == 3"
          title="辅料类型"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="addForm.remark"
          :disabled="dialogType == 3"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer" v-if="dialogType != 3">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { createAccessory, updateAccessory } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "addAuxiliaryMaterial",
  components: {},
  data() {
    return {
      editDialogLoading: false,
      contactTypeList: [],
      addForm: {
        accessoryId: "",
        accessoryName: "",
        accessoryType: "",
        accessoryClass: "",
        enableStatus: "",
        remark: ""
      },
      addFormRules: {
        accessoryName: [
          {
            required: true,
            message: "请输入辅料名称",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  //dialogType:1新增2编辑3详情
  props: ["addVisible", "dialogType", "detailInfo", "accessoryClassList"],
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.addVisible;
      },
      set(value) {
        this.$emit("update:addVisible", value);
      }
    }
  },
  created() {},
  watch: {
    addVisible(val) {
      if (val && this.detailInfo.accessoryName && this.dialogType != 1) {
        this.addForm = { ...this.detailInfo };
        this.addForm.accessoryClass = this.detailInfo.accessoryClass.toString();
      }
    }
  },
  methods: {
    //点击确定添加
    handleSubmit() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          //新增
          if (this.dialogType == 1) {
            this.createAccessoryReq();
          }
          //编辑
          if (this.dialogType == 2) {
            this.updateAccessoryReq();
          }
        }
      });
    },

    //添加请求
    async createAccessoryReq() {
      try {
        this.editDialogLoading = true;
        let res = await createAccessory(this.addForm);
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //编辑接口
    async updateAccessoryReq() {
      try {
        this.editDialogLoading = true;
        let res = await updateAccessory(this.addForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.addForm = {
        accessoryName: "",
        accessoryType: "",
        accessoryClass: "",
        enableStatus: "",
        remark: ""
      };
      this.$refs.addForm.resetFields();
      this.visible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/utils.scss";
@import "@/assets/styles/variables.scss";
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;
    .el-form {
      .el-form-item {
        .e6-vr-select {
          width: 300px;
          .el-input {
            width: 300px;
          }
        }
        .el-input {
          width: 300px;
        }
      }
    }
  }
}
</style>
